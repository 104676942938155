import log from 'consola'
import { Craft_Shape_Enum_Enum, SightingInput, SubmitSightingMutation } from '~/utils/generated/graphql'

const _initialSightingResult = () => useState<null | SubmitSightingMutation>('initialSightingResult', () => null)

const _craftShapeInsertInput = computed<Craft_Shape_Enum_Enum | null>(() => {
  const behaviorInsertInput = useBehavior().insertInput()
  const selectedShape = behaviorInsertInput.features[1].selected

  // if user enters custom input
  if (typeof selectedShape !== 'string') {
    return null
  }

  const index = Object.values(Craft_Shape_Enum_Enum).indexOf(selectedShape.toUpperCase() as unknown as Craft_Shape_Enum_Enum)
  const key = Object.keys(Craft_Shape_Enum_Enum)[index]

  return Craft_Shape_Enum_Enum[key as keyof typeof Craft_Shape_Enum_Enum]
})

function _additionalData() {
  const behaviorInsertInput = useBehavior().insertInput()
  const { otherSensorInput } = useSensorSlide()
  const featureArray = behaviorInsertInput.features
  const otherFeatureArray = behaviorInsertInput.otherFeatures
  // behavior features
  const craftShape = featureArray[1].selected.length === 0 ? featureArray[1].customInput : featureArray[1].selected
  const craftDistance = featureArray[2].selected || featureArray[2].customInput
  const craftSize = featureArray[3].selected.length === 0 ? featureArray[3].customInput : featureArray[3].selected
  // madlib features
  const craftHasWings = otherFeatureArray[0].selected
  const craftHasRotors = otherFeatureArray[1].selected
  const craftHasExhaustPlume = otherFeatureArray[2].selected
  const craftMakesNoise = otherFeatureArray[3].selected

  return {
    shape: craftShape,
    distance: craftDistance,
    size: craftSize,
    movement: featureArray[4].selected,
    craft_has_wings: craftHasWings,
    craft_has_rotors: craftHasRotors,
    craft_has_exhaust_plume: craftHasExhaustPlume,
    craft_makes_noise: craftMakesNoise,
    sensors: otherSensorInput.value
  }
}

const _submitSightingInput = computed<SightingInput | null>(() => {
  const craftShape = _craftShapeInsertInput
  const additionalData = _additionalData()
  const { dateTimeInput, durationInput, timeZone } = useDateTimeSlide()
  const { markerLocation, geocodeSelection, yesOrNoPII } = useLocationSlide()
  const { storyInput } = useStorySlide()
  const { twitterInput, instagramInput, tiktokInput } = useSocialSlide()
  const { witnessCountInput } = useWitnessCountSlide()
  const { phoneNumberFull, emailInput, emailOptIn, smsOptIn, submitAnonymously } = useNameSlide()
  const { fingerprint } = useFingerprint()
  const { expertiseOptions, sensorOptions } = useSensorSlide()
  const behaviorInsertInput = useBehavior().insertInput()
  const { files, audioFiles } = useFileManager()

  const isLocationPII = yesOrNoPII.value === 'yes'
  const craftShapeEnum = craftShape.value || 'OTHER'
  const objectCount = behaviorInsertInput.features[0].selected === '5+' ? 999 : Number(behaviorInsertInput.features[0].selected)
  const lngLatString = `[${markerLocation?.value?.lat.toFixed(3)}, ${markerLocation?.value?.lng.toFixed(3)}]`
  let socialMediaFields = []
  const offset = timeZone.value ? timeZone.value.timeZoneId : 'Europe/London'
  const zoned = zonedDatetime(dateTimeInput.value, offset)

  if (!!twitterInput.value) {
    socialMediaFields.push({
      media_name: 'twitter',
      media_url: userProfileURL(twitterInput.value, 'twitter'),
      user_handle: `${twitterInput.value}`
    })
  }

  if (!!instagramInput.value) {
    socialMediaFields.push({
      media_name: 'instagram',
      media_url: userProfileURL(instagramInput.value, 'instagram'),
      user_handle: `${instagramInput.value}`
    })
  }

  if (!!tiktokInput.value) {
    socialMediaFields.push({
      media_name: 'tiktok',
      media_url: userProfileURL(tiktokInput.value, 'tiktok'),
      user_handle: `${tiktokInput.value}`
    })
  }

  if (markerLocation.value === null) {
    log.error('Location insert is null')
    return null
  }

  if (witnessCountInput.value === null) {
    log.error('Witness count input is null')
    return null
  }

  if (durationInput.value === null) {
    log.error('Duration input is null')
    return null
  }

  if (craftShapeEnum === null) {
    log.error('Craft shape input is null')
    return null
  }

  if (storyInput.value === null && audioFiles.value.length === 0) {
    log.error('Description input is null')
    return null
  }

  if (dateTimeInput.value === null || zoned.toISO() === null) {
    log.error('Datetime input is null')
    return null
  }

  const submitterSocialMedia = socialMediaFields.length > 0 ? { social_media: socialMediaFields } : null

  return {
    data_source_id: 'ENIGMA_WEB',
    description: storyInput.value || '', // if audio
    started_at: zoned.toISO() || '',
    location_name: geocodeSelection.value?.place_name || lngLatString, // fallback for dropping a pin
    is_location_pii: isLocationPII,
    latitude: markerLocation.value.lat,
    longitude: markerLocation.value.lng,
    duration: durationInput.value,
    shape: craftShapeEnum,
    witness_count: witnessCountInput.value,
    sms_opt_in: smsOptIn.value,
    submit_anonymously: submitAnonymously.value,
    number_of_media: files.value.length,
    number_of_objects: objectCount,
    // optional fields
    submitter_social_media: submitterSocialMedia,
    additional_data: additionalData,
    email: emailInput.value || '',
    email_opt_in: emailOptIn.value,
    location_query_response: geocodeSelection.value,
    phone: phoneNumberFull.value,
    visitor_id: useRuntimeConfig().public.environment === 'development' ? 'test' : fingerprint.value,
    professions: expertiseOptions.value
      .filter(f => f.selected)
      .map(f => f.description)
      .join(', '),
    sensors: sensorOptions.value
      .filter(s => s.selected)
      .map(s => s.description)
      .join(', ')
  }
})

export default function () {
  return {
    submitSightingInput: _submitSightingInput,
    initialSightingResult: _initialSightingResult()
  }
}
